<template>
  <div
    class="vd-padding-small vd-border-light-grey vd-border-width-0 vd-border-bottom-width-1"
  >
    <div class="vd-text-align-right">
      <button v-if="selectedProjectId" @click="handleViewProject">
        <span
          class="vd-icon vd-icon-circle vd-hover-background-light-blue vd-hover-white icon-eye"
        ></span>
      </button>
      <template v-if="!eventData.IsReadonly">
        <button @click="handleEdit">
          <span
            class="vd-icon vd-icon-circle vd-hover-background-light-blue vd-hover-white icon-pencil"
          ></span>
        </button>
        <button @click="handleDelete">
          <span
            class="vd-icon vd-icon-circle vd-hover-background-light-blue vd-hover-white icon-bin"
          ></span>
        </button>
      </template>
      <button @click="handleClose">
        <span
          class="vd-icon vd-icon-circle vd-hover-background-light-blue vd-hover-white icon-xmark"
        ></span>
      </button>
    </div>
    <div class="vd-h4 vd-padding-inline-medium">
      <strong>{{ eventName }} <span v-if="isOnHold">(On Hold)</span> </strong>
    </div>
  </div>
</template>

<script>
import router from '@router/router'

export default {
  props: {
    eventData: {
      type: Object,
      required: true,
    },

    selectedEvent: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },

    eventName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedProjectId: null,
    }
  },

  watch: {
    selectedEvent() {
      let projectId = null

      if (this.selectedEvent?.project) {
        projectId = this.selectedEvent.project.id
      } else if (this.selectedEvent?.meta_data) {
        projectId = this.selectedEvent.meta_data.project_id
      }

      this.selectedProjectId = projectId
    },
  },

  methods: {
    handleClose() {
      const scheduleObj = document.querySelector('.e-schedule').ej2_instances[0]
      scheduleObj.closeQuickInfoPopup()
    },

    handleDelete() {
      this.$emit('click:delete', this.eventData)
      this.handleClose()
    },

    handleEdit() {
      this.$emit('click:edit', this.eventData)
      this.handleClose()
    },

    handleViewProject() {
      const projectUrl = router.resolve({
        name: 'project.details.summary',
        params: {
          projectId: this.selectedProjectId,
        },
      })

      window.open(projectUrl.href, '_blank')
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-project-schedule__scheduler vd-position-relative"},[_c('ejs-schedule',{ref:"vmsScheduler",attrs:{"id":"VmsProjectSchedule","height":_vm.isMobileScreen ? 'calc(100vh)' : 'calc(100vh - 480px)',"selected-date":_vm.selectedDate,"current-view":_vm.selectedViewType,"views":_vm.schedulerViews,"event-settings":_vm.eventSettings,"event-rendered":_vm.onEventRendered,"allow-multi-drag":true,"drag-stop":_vm.handleDragStop,"timezone":_vm.defaultCalendarTimezone,"quick-info-templates":_vm.quickInfoTemplates,"date-header-template":"dateHeaderTimelineTemplate","resource-header-template":"resourceHeaderTemplate","group":_vm.resourceGroup},on:{"resizeStop":_vm.handleResizeStop,"navigating":_vm.handleSchedulerNavigation,"select":_vm.handleSelect,"eventDoubleClick":_vm.handleEventDoubleClick,"cellDoubleClick":_vm.cancelEvent,"cellClick":_vm.cancelEvent,"popupOpen":_vm.handlePopupOpen},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.handleEventRightClicked($event)}},scopedSlots:_vm._u([{key:"popupHeaderTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-popover-header',{style:(_vm.getPopoverBodyStyle(data)),attrs:{"event-data":data,"selected-event":_vm.selectedEvent,"event-name":_vm.eventName(data),"is-on-hold":_vm.isEventOnHold(data.event_status_id)},on:{"click:edit":_vm.openEditModal,"click:delete":_vm.handleDeleteEvent}})]}},{key:"popupContentTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-popover-content',{attrs:{"event-data":data,"selected-event":_vm.selectedEvent,"is-event-details-loading":_vm.isEventDetailsLoading,"get-event-details":_vm.getEventDetails,"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"is-external-event":_vm.isExternalEvent(data.event_type_id)}})]}},{key:"dateHeaderTimelineTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-header',{attrs:{"event-data":data,"holidays":_vm.getDateHolidays(data)}})]}},{key:"resourceHeaderTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-resource-header',{attrs:{"event-data":data},on:{"handle:remove-user-click":function($event){return _vm.handleRemoveCrew($event)}}})]}}])},[_c('e-resources',[_c('e-resource',{attrs:{"data-source":_vm.teamsResourceGroup,"field":"TeamGroupId","title":"Teams","name":"teams","text-field":"teamName","id-field":"teamId"}}),_c('e-resource',{attrs:{"data-source":_vm.usersResourceGroup,"field":"UserGroupId","title":"Users","name":"users","text-field":"userName","id-field":"userId","group-i-d-field":"teamId"}})],1),_c('e-views',[_c('e-view',{attrs:{"option":"TimelineMonth","display-name":"Timeline","event-template":'timelineMonthViewTemplate'},scopedSlots:_vm._u([{key:"timelineMonthViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"event-name":_vm.eventName(data),"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}])})],1)],1),_c('events-context-menu',{ref:"calendarContextMenu",attrs:{"menu-items":_vm.calendarActionContextMenu},on:{"handle:context-menu-close":_vm.clearSelectedCells}}),_c('events-context-menu',{ref:"calendarBulkActionContextMenu",attrs:{"menu-items":_vm.calendarBulkMenuItems,"before-open":_vm.onBulkContextMenuBeforeOpen},on:{"handle:context-menu-close":_vm.clearSelectedEvents,"handle:content-menu-select":_vm.handleBulkActionContextMenuSelect}}),_c('events-context-menu',{ref:"calendarActionContextMenu",attrs:{"menu-items":_vm.calendarMenuItems,"before-open":_vm.onContextMenuBeforeOpen},on:{"handle:context-menu-close":_vm.clearSelectedEvents,"handle:content-menu-select":_vm.handleActionContextMenuSelect}}),(_vm.showEmptyState)?_c('div',{staticClass:"vms-project-schedule__empty vd-position-absolute vd-display-flex vd-align-center vd-justify-center vd-full-width vd-full-size vd-pointer-"},[_c('div',{staticClass:"vd-text-align-center"},[_c('p',{staticClass:"vd-margin-bottom-medium vd-red"},[_vm._v(" No Events to display for this month ")]),_c('button',{staticClass:"vd-btn-medium vd-btn-blue",on:{"click":function($event){return _vm.showContextMenu({ event: $event })}}},[_vm._v(" Add new event ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="vms-project-schedule__scheduler vd-position-relative">
    <ejs-schedule
      id="VmsProjectSchedule"
      ref="vmsScheduler"
      :height="isMobileScreen ? 'calc(100vh)' : 'calc(100vh - 480px)'"
      :selected-date="selectedDate"
      :current-view="selectedViewType"
      :views="schedulerViews"
      :event-settings="eventSettings"
      :event-rendered="onEventRendered"
      :allow-multi-drag="true"
      :drag-stop="handleDragStop"
      :timezone="defaultCalendarTimezone"
      :quick-info-templates="quickInfoTemplates"
      date-header-template="dateHeaderTimelineTemplate"
      resource-header-template="resourceHeaderTemplate"
      :group="resourceGroup"
      @resizeStop="handleResizeStop"
      @navigating="handleSchedulerNavigation"
      @select="handleSelect"
      @eventDoubleClick="handleEventDoubleClick"
      @cellDoubleClick="cancelEvent"
      @cellClick="cancelEvent"
      @click.native.right.prevent="handleEventRightClicked"
      @popupOpen="handlePopupOpen"
    >
      <template v-slot:popupHeaderTemplate="{ data }">
        <events-popover-header
          :style="getPopoverBodyStyle(data)"
          :event-data="data"
          :selected-event="selectedEvent"
          :event-name="eventName(data)"
          :is-on-hold="isEventOnHold(data.event_status_id)"
          @click:edit="openEditModal"
          @click:delete="handleDeleteEvent"
        >
        </events-popover-header>
      </template>
      <template v-slot:popupContentTemplate="{ data }">
        <events-popover-content
          :event-data="data"
          :selected-event="selectedEvent"
          :is-event-details-loading="isEventDetailsLoading"
          :get-event-details="getEventDetails"
          :is-placeholder-event-type="isPlaceholderEventType(data)"
          :is-external-event="isExternalEvent(data.event_type_id)"
        ></events-popover-content>
      </template>

      <e-resources>
        <e-resource
          :data-source="teamsResourceGroup"
          field="TeamGroupId"
          title="Teams"
          name="teams"
          text-field="teamName"
          id-field="teamId"
        ></e-resource>
        <e-resource
          :data-source="usersResourceGroup"
          field="UserGroupId"
          title="Users"
          name="users"
          text-field="userName"
          id-field="userId"
          group-i-d-field="teamId"
        >
        </e-resource>
      </e-resources>

      <template v-slot:dateHeaderTimelineTemplate="{ data }">
        <events-timeline-header
          :event-data="data"
          :holidays="getDateHolidays(data)"
        ></events-timeline-header>
      </template>

      <template v-slot:resourceHeaderTemplate="{ data }">
        <events-timeline-resource-header
          :event-data="data"
          @handle:remove-user-click="handleRemoveCrew($event)"
        ></events-timeline-resource-header>
      </template>

      <e-views>
        <e-view
          option="TimelineMonth"
          display-name="Timeline"
          :event-template="'timelineMonthViewTemplate'"
        >
          <template v-slot:timelineMonthViewTemplate="{ data }">
            <events-timeline-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :event-name="eventName(data)"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></events-timeline-view>
          </template>
        </e-view>
      </e-views>
    </ejs-schedule>

    <events-context-menu
      ref="calendarContextMenu"
      :menu-items="calendarActionContextMenu"
      @handle:context-menu-close="clearSelectedCells"
    ></events-context-menu>

    <events-context-menu
      ref="calendarBulkActionContextMenu"
      :menu-items="calendarBulkMenuItems"
      :before-open="onBulkContextMenuBeforeOpen"
      @handle:context-menu-close="clearSelectedEvents"
      @handle:content-menu-select="handleBulkActionContextMenuSelect"
    ></events-context-menu>

    <events-context-menu
      ref="calendarActionContextMenu"
      :menu-items="calendarMenuItems"
      :before-open="onContextMenuBeforeOpen"
      @handle:context-menu-close="clearSelectedEvents"
      @handle:content-menu-select="handleActionContextMenuSelect"
    ></events-context-menu>

    <div
      v-if="showEmptyState"
      class="vms-project-schedule__empty vd-position-absolute vd-display-flex vd-align-center vd-justify-center vd-full-width vd-full-size vd-pointer-"
    >
      <div class="vd-text-align-center">
        <p class="vd-margin-bottom-medium vd-red">
          No Events to display for this month
        </p>
        <button
          class="vd-btn-medium vd-btn-blue"
          @click="showContextMenu({ event: $event })"
        >
          Add new event
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import '@plugins/syncfusion'
import { mapGetters, mapActions } from 'vuex'
import {
  TimelineMonth,
  Resize,
  DragAndDrop,
  ViewsDirective,
  ViewDirective,
  ResourceDirective,
  ResourcesDirective,
} from '@syncfusion/ej2-vue-schedule'
import {
  forEach,
  assign,
  map,
  includes,
  filter,
  isEmpty,
  some,
  orderBy,
  uniqBy,
} from 'lodash'
import momentTz from 'moment-timezone'
import EventsPopoverHeader from '@components/events/events-popover/events-popover-header'
import EventsPopoverContent from '@components/events/events-popover/events-popover-content'
import EventsTimelineView from '@components/events/events-timeline/events-timeline-view'
import EventsTimelineHeader from '@components/events/events-timeline/events-timeline-header'
import EventsTimelineResourceHeader from '@components/events/events-timeline/events-timeline-resource-header'
import FiltersMixin from '@mixins/filters-mixin'
import EventsContextMenu from '@components/events/events-context-menu'
import EventUserGroups from '@configs/event-user-groups'
import EventSchedulerMixin from '@mixins/event-scheduler-mixin'

export default {
  components: {
    'e-views': ViewsDirective,
    'e-view': ViewDirective,
    'e-resource': ResourceDirective,
    'e-resources': ResourcesDirective,
    EventsPopoverHeader,
    EventsPopoverContent,
    EventsContextMenu,
    EventsTimelineView,
    EventsTimelineHeader,
    EventsTimelineResourceHeader,
  },

  mixins: [EventSchedulerMixin, FiltersMixin],

  provide: {
    schedule: [TimelineMonth, Resize, DragAndDrop],
  },

  data() {
    return {
      schedulerViews: ['TimelineMonth'],
      quickInfoTemplates: {
        header: 'popupHeaderTemplate',
        content: 'popupContentTemplate',
      },

      selectedEventsArray: [],

      resourceGroup: {
        resources: ['teams', 'users'],
      },

      calendarBulkMenuItems: [
        {
          text: 'Bulk edit',
          id: 'bulk-edit',
        },
        {
          text: 'Bulk duplicate',
          id: 'bulk-duplicate',
        },
        {
          text: 'Bulk delete',
          id: 'bulk-delete',
        },
      ],

      calendarActionContextMenu: [
        {
          text: 'New filming event',
          id: 'filming-event',
        },
        {
          text: 'New edit event',
          id: 'edit-event',
        },
        {
          text: 'New pre-prod event',
          id: 'pre-production-event',
        },
      ],

      calendarMenuItems: [
        {
          text: 'Delete',
          id: 'delete',
        },
        {
          text: 'Duplicate',
          id: 'duplicate',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      eventsList: 'calendar/eventsList',
      selectedViewType: 'calendar/selectedViewType',
      isEventDetailsLoading: 'calendar/isEventDetailsLoading',
      selectedEvent: 'calendar/selectedEvent',
      unassignedTeamGroup: 'calendar/unassignedTeamGroup',
      projectDetails: 'project/projectDetails',
      productionTeam: 'project/productionTeam',
      projectEventsListPayload: 'project/schedule/projectEventsListPayload',
      usersList: 'project/schedule/usersList',
      defaultCalendarTimezone: 'common/defaultCalendarTimezone',
      selectedDate: 'project/schedule/selectedDate',
      isMobileScreen: 'common/isMobileScreen',
    }),

    eventSettings() {
      return {
        dataSource: this.getFilteredEventDataSource(),
      }
    },

    showEmptyState() {
      return isEmpty(this.usersResourceGroup)
    },

    currentProjectEvents() {
      return filter(this.eventsList, (event) => {
        return this.isCurrentProjectEvent(event)
      })
    },

    usersResourceGroup() {
      // from events list
      let userGroup = []

      // add user group
      const eventUsersFromCurrentProject = map(
        this.currentProjectEvents,
        (projectEvent) => projectEvent.user_id
      )
      const productionTeamIds = map(
        this.productionTeam,
        (teamMember) => teamMember.id
      )

      forEach(this.usersList, (user) => {
        if (!user.hasEventFromCurrentProject) {
          user.hasEventFromCurrentProject = includes(
            eventUsersFromCurrentProject,
            user.id
          )
        }

        user.isCrewMember = includes(productionTeamIds, user.id)

        userGroup.push(this.getUserTeamGroup(user))
      })

      // add freelancer and unassigned group
      forEach(this.eventSettings.dataSource, (event) => {
        if (!event.IsHoliday) {
          const freelancer = event.meta_data?.freelancer
          if (freelancer) {
            userGroup.push(this.getFreelancerGroup(freelancer))
          } else if (!event.user) {
            userGroup.push(this.unassignedTeamGroup)
          }
        }
      })

      // return array of unique group of id and teamId
      return orderBy(uniqBy(userGroup, 'userId'), ['userName'])
    },

    teamsResourceGroup() {
      return [
        { teamId: EventUserGroups.USER, teamName: 'Crew' },
        { teamId: EventUserGroups.FREELANCER, teamName: 'Freelancers' },
        { teamId: EventUserGroups.UNASSIGNED, teamName: 'Unassigned' },
      ]
    },
  },

  watch: {
    // update scheduler source to refresh it when data source changed
    eventSettings() {
      this.updateEventSettingsDataSource()
    },

    showEventsBulkEditModal() {
      if (!this.showEventsBulkEditModal) {
        this.selectedEventsArray = []
      }
    },
  },

  // TODO
  // Keeping both for now, we need to double check if we need this when keep-alive is implemented
  mounted() {
    this.setViewType('TimelineMonth')
  },

  created() {
    this.setViewType('TimelineMonth')
  },

  methods: {
    ...mapActions({
      getEventDetails: 'calendar/getEventDetails',
      getProjectEventsList: 'project/schedule/getProjectEventsList',
      setSelectedDate: 'project/schedule/setSelectedDate',
      setUsersList: 'project/schedule/setUsersList',
      setViewType: 'calendar/setViewType',
    }),

    getFilteredEventDataSource() {
      const dataSource = this.getEventSettingsDataSource()
      return this.filterEventDataSource(dataSource)
    },

    updateEventSettingsDataSource() {
      // update the scheduler component dataSource
      if (this.$refs.vmsScheduler) {
        this.$refs.vmsScheduler.ej2Instances.eventSettings.dataSource = this.getFilteredEventDataSource()
      }
    },

    handleSchedulerNavigation(args) {
      if (args.action === 'date') {
        // call new events list
        const { start, end } = this.projectEventsListPayload

        if (
          !momentTz(args.currentDate).isBetween(start, end, undefined, '[]')
        ) {
          this.setSelectedDate(args.currentDate)
          this.getProjectEventsList({
            payload: this.projectEventsListPayload,
            showLoader: false,
          })
        }
      }
    },

    handleRemoveCrew(userId) {
      const user = filter(this.usersList, (user) => user.id !== userId)
      this.setUsersList(user)
      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: false,
      })
    },

    isCurrentProjectEvent(event) {
      return event.project?.id === this.projectDetails?.id
    },

    getUserTeamGroup(user) {
      return {
        userId: user.id,
        userName: user.full_name,
        data: {
          full_name: user.full_name,
          calendar_color: user.calendar_color,
          photo_url: user.photo_url,
        },
        teamId: EventUserGroups.USER,
        isRemovable: !user.hasEventFromCurrentProject && !user.isCrewMember,
      }
    },

    getUserTeamGroupIds(eventData, dataSourceItem) {
      const user = eventData.user
      const freelancer = eventData.meta_data?.freelancer

      if (freelancer) {
        return [
          assign(dataSourceItem, {
            UserGroupId: freelancer,
            TeamGroupId: EventUserGroups.FREELANCER,
          }),
        ]
      } else {
        if (user) {
          return [
            assign(dataSourceItem, {
              UserGroupId: user.id,
              TeamGroupId: EventUserGroups.USER,
            }),
          ]
        } else {
          return [
            assign(dataSourceItem, {
              UserGroupId: EventUserGroups.UNASSIGNED,
              TeamGroupId: EventUserGroups.UNASSIGNED,
            }),
          ]
        }
      }
    },

    filterEventDataSource(eventDataSource) {
      return filter(eventDataSource, (event) => {
        // add freelancer and unassigned groups when event is not a holiday and does not belong to any user team group
        if (!event.IsHoliday && event.TeamGroupId !== EventUserGroups.USER) {
          return some(this.currentProjectEvents, (projectEvents) => {
            if (event.TeamGroupId === EventUserGroups.FREELANCER) {
              // includes the freelancer events when at least 1 event belongs to current project
              return projectEvents.meta_data?.freelancer === event.UserGroupId
            } else {
              // only includes unassigned that belongs to the current project
              return (
                !isEmpty(event.project) &&
                projectEvents.project.id === event.project.id
              )
            }
          })
        }

        // return the event by default
        return true
      })
    },
  },
}
</script>

<style>
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';

@import '~@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-vue-inputs/styles/material.css';
@import '~@syncfusion/ej2-vue-navigations/styles/material.css';
@import '~@syncfusion/ej2-vue-popups/styles/material.css';
@import '~@syncfusion/ej2-vue-schedule/styles/material.css';
</style>

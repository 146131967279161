import Vue from 'vue'
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule'
import { ContextMenuPlugin } from '@syncfusion/ej2-vue-navigations'
import { CalendarPlugin } from '@syncfusion/ej2-vue-calendars'
import { ProgressBarPlugin } from '@syncfusion/ej2-vue-progressbar'

Vue.use(SchedulePlugin)
Vue.use(ContextMenuPlugin)
Vue.use(CalendarPlugin)
Vue.use(ProgressBarPlugin)

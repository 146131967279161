<template>
  <div>
    <div
      v-if="eventData.resource.name === 'users'"
      class="vd-display-flex vd-align-center vd-full-width"
    >
      <user-profile
        :user="eventData.resourceData.data"
        :custom-avatar-font-size="35"
      ></user-profile>
      <div class="vd-margin-left-small vd-margin-right-auto">
        <span v-if="eventData.resourceData.userName">
          {{ eventData.resourceData.userName | truncateString(25) }}
        </span>
        <span v-else> - </span>
      </div>
      <button
        v-if="eventData.resourceData.isRemovable"
        id="handleRemoveButton"
        class="vd-margin-inline-medium vd-align-middle vd-text-extra-small"
        @click="handleRemoveUserClick(eventData.resourceData.userId)"
      >
        <span
          class="vd-icon icon-xmark vd-icon-circle vd-background-lighter-grey vd-hover-background-light-grey vd-dark-grey vd-hover-darker-grey"
        ></span>
      </button>
    </div>
    <div v-else class="e-resource-text">
      {{ eventData.resourceData.teamName || '-' }}
    </div>
  </div>
</template>

<script>
import UserProfile from '@/components/user-profile/user-profile'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleRemoveUserClick(userId) {
      this.$emit('handle:remove-user-click', userId)
    },
  },
}
</script>

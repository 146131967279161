<template>
  <div
    v-if="eventData"
    :class="[
      'vd-padding-extra-small vd-text-align-center',
      hasHolidays ? 'vd-background-light-grey' : '',
    ]"
  >
    <div class="vd-h5 vd-text-align-center vd-margin-bottom-extra-small">
      {{ eventData.date | momentFormat('D') }}
    </div>
    <div class="vd-opacity vd-text-align-center vd-text-small">
      {{ eventData.date | momentFormat('ddd') }}
    </div>
    <text-popover
      v-if="hasHolidays"
      :use-label-slot="true"
      :show-icon="false"
      :tooltip-id="`holiday-${eventData.date}`"
    >
      <div
        :id="`holiday-${eventData.date}`"
        slot="popover-label"
        class="vd-margin-block-extra-small vd-background-darker-grey vd-white vd-border-radius-2 vd-cursor-pointer vd-text-extra-small"
      >
        Public holiday
      </div>
      <div slot="popover-body">
        <div v-for="(holiday, index) in holidays" :key="index">
          {{ holiday.Subject }}
        </div>
      </div>
    </text-popover>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import TextPopover from '@components/tooltips/text-popover'
import { isEmpty } from 'lodash'

export default {
  components: {
    TextPopover,
  },

  mixins: [FiltersMixin],
  props: {
    eventData: {
      type: Object,
      required: true,
    },
    holidays: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasHolidays() {
      return !isEmpty(this.holidays)
    },
  },
}
</script>

<template>
  <div>
    <ejs-contextmenu
      id="contextmenu"
      ref="menuObj"
      css-class="schedule-context-menu"
      :items="menuItems"
      :select="onContextMenuItemSelect"
      :before-open="beforeOpen"
      @onClose="clearSelectedCells"
    ></ejs-contextmenu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    menuItems: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Add placeholder',
            id: 'placeholder-event',
          },
          {
            text: 'New filming event',
            id: 'filming-event',
          },
          {
            text: 'New edit event',
            id: 'edit-event',
          },
          {
            text: 'New pre-prod event',
            id: 'pre-production-event',
          },
        ]
      },
    },
    beforeOpen: {
      type: Function,
      default: null,
    },
  },

  methods: {
    ...mapActions({
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsFilmingDetailsCreateModal:
        'calendar/setShowEventsFilmingDetailsCreateModal',
      setShowEventsDeliveriesCreateModal:
        'calendar/setShowEventsDeliveriesCreateModal',
      setShowEventsPreProductionsCreateModal:
        'calendar/setShowEventsPreProductionsCreateModal',
      setShowEventsPlaceholdersCreateModal:
        'calendar/setShowEventsPlaceholdersCreateModal',
    }),

    onContextMenuItemSelect(args) {
      this.$emit('handle:content-menu-select', args.item)

      if (args.item) {
        this.handleDefaultMenuItemSelect(args.item.id)
      }
    },

    handleDefaultMenuItemSelect(menuId) {
      switch (menuId) {
        case 'filming-event':
          this.setShowEventsModal(true)
          this.setShowEventsFilmingDetailsCreateModal(true)
          break
        case 'edit-event':
          this.setShowEventsModal(true)
          this.setShowEventsDeliveriesCreateModal(true)
          break
        case 'pre-production-event':
          this.setShowEventsModal(true)
          this.setShowEventsPreProductionsCreateModal(true)
          break
        case 'placeholder-event':
          this.setShowEventsModal(true)
          this.setShowEventsPlaceholdersCreateModal(true)
          break
        default:
          break
      }
    },

    clearSelectedCells() {
      this.$emit('handle:context-menu-close')
    },
  },
}
</script>

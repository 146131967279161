<template>
  <div
    v-if="copyContent"
    class="vms-copy-clipboard--container vd-position-relative"
  >
    <div
      class="vms-copy-clipboard--container vd-full-width"
      @click="copyToClipboard"
    >
      <slot>
        <div class="vms-copy-clipboard--icon-link"></div>
      </slot>
    </div>

    <div
      v-if="copied"
      class="vms-copy-clipboard--icon--container vd-position-absolute vd-background-lightest-grey vd-padding-inline-small vd-border-radius-6 vd-box-shadow"
    >
      <span class="vd-icon-tick-green vms-copy-clipboard--icon-success"></span>
      <span class="vms-copy-clipboard--label vd-green vd-h5">
        {{ successMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from 'copy-text-to-clipboard'

export default {
  props: {
    successMessage: {
      type: String,
      required: false,
      default: 'Copied!',
    },

    copyContent: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      copied: false,
    }
  },

  methods: {
    copyToClipboard() {
      const vm = this
      this.copied = CopyToClipboard(this.copyContent)

      if (this.copied) {
        setTimeout(() => {
          vm.copied = false
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/clipboard-copy';
</style>

import Colours from '@configs/colours'
import momentTz from 'moment-timezone'
import { mapGetters, mapActions } from 'vuex'
import EventUserGroups from '@configs/event-user-groups'
import VmsConstants from '@configs/vms-constants'
import SweetAlert from '@plugins/sweet-alert'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { closest, removeClass } from '@syncfusion/ej2-base'

import {
  forEach,
  assign,
  size,
  map,
  includes,
  filter,
  parseInt,
  isEmpty,
  find,
  some,
  uniqWith,
  uniq,
  isFunction,
  every,
} from 'lodash'

/**
 * @namespace mixins/event-scheduler-mixin
 */

export default {
  beforeDestroy() {
    // Reset to default timezone on page leave
    momentTz.tz.setDefault(this.defaultCalendarTimezone)
  },

  deactivated() {
    // Reset to default timezone on page leave
    momentTz.tz.setDefault(this.defaultCalendarTimezone)
  },

  computed: {
    ...mapGetters({
      bulkActionSelectedEvents: 'calendar/bulkActionSelectedEvents',
      defaultCalendarTimezone: 'common/defaultCalendarTimezone',
      eventsList: 'calendar/eventsList',
      selectedEvent: 'calendar/selectedEvent',
      showEventsBulkEditModal: 'calendar/showEventsBulkEditModal',
      internalUsersList: 'user/internalUsersList',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedViewType: 'calendar/selectedViewType',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isTimelineView() {
      return this.selectedViewType === 'TimelineMonth'
    },
  },
  methods: {
    ...mapActions({
      setSelectedEvent: 'calendar/setSelectedEvent',
      getEventDetails: 'calendar/getEventDetails',
      updateEventDetails: 'calendar/updateEventDetails',
      deleteEvent: 'calendar/deleteEvent',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsBulkEditModal: 'calendar/setShowEventsBulkEditModal',
      setBulkActionSelectedEvents: 'calendar/setBulkActionSelectedEvents',
      bulkDeleteEvents: 'calendar/bulkDeleteEvents',
      bulkDuplicateEvents: 'calendar/bulkDuplicateEvents',
      bulkUpdateEvents: 'calendar/bulkUpdateEvents',
      setShowEventsPlaceholdersCreateModal:
        'calendar/setShowEventsPlaceholdersCreateModal',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setProjectsList: 'calendar/setProjectsList',
      updateProjectEventDetails: 'project/schedule/updateProjectEventDetails',
      setEventActionHistory: 'calendar/setEventActionHistory',
    }),

    getDateHolidays(data) {
      // use default timezone
      momentTz.tz.setDefault(this.defaultCalendarTimezone)
      const dateIsHoliday = filter(this.eventSettings.dataSource, (event) => {
        if (event.IsHoliday) {
          const holidayDate = momentTz.unix(event.from).format('YYYY-MM-DD')
          const dataDate = momentTz(data.date).format('YYYY-MM-DD')
          return holidayDate === dataDate
        }

        return false
      })
      // reset momentTz timezone
      momentTz.tz.setDefault()
      return dateIsHoliday
    },

    getEventSettingsDataSource() {
      const dataSource = []

      // disable setting default timezone to accommodate for system timezone changes
      momentTz.tz.setDefault()

      forEach(this.eventsList, (eventData) => {
        const dataSourceItem = assign({}, eventData, {
          Id: eventData.id,
          Subject: eventData.name,
          StartTime: momentTz
            .unix(eventData.from)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          EndTime: momentTz.unix(eventData.to).format('YYYY-MM-DDTHH:mm:ssZ'),
          IsReadonly: this.isEventReadOnly(eventData),
          IsHoliday: this.isPublicHolidayEvent(eventData.event_type_id),
        })

        if (this.isTimelineView) {
          dataSource.push(
            ...this.getUserTeamGroupIds(eventData, dataSourceItem)
          )
        } else {
          dataSource.push(dataSourceItem)
        }
      })

      return dataSource
    },

    eventDisplayColors(eventData) {
      let eventBackgroundColor = Colours.eventColours.unassigned
      let textColor = Colours.eventColours.defaultText

      if (this.isPublicHolidayEvent(eventData.event_type_id)) {
        eventBackgroundColor = Colours.eventColours.publicHoliday
      } else if (this.isEventFromOtherProject(eventData)) {
        eventBackgroundColor = Colours.eventColours.otherProject
        textColor = this.$options.filters.getForeColor(eventBackgroundColor)
      } else {
        if (eventData.user?.calendar_color) {
          eventBackgroundColor = eventData.user.calendar_color
        } else if (eventData.meta_data?.freelancer) {
          eventBackgroundColor = Colours.eventColours.freelancer
        }
        textColor = this.$options.filters.getForeColor(eventBackgroundColor)
      }

      return {
        // Add opacity 80% (0.8) to slightly match popup color and remove the strong bright color on calendar.
        backgroundColor: `${eventBackgroundColor}cc`,
        textColor: textColor,
        originalBgColor: eventBackgroundColor,
      }
    },

    isEventOnHold(eventStatusId) {
      return (
        eventStatusId === VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD
      )
    },

    linearGradientBg(eventData) {
      const eventColor = {
        original: this.eventDisplayColors(eventData).originalBgColor,
        opacity: this.eventDisplayColors(eventData).backgroundColor,
      }

      return `repeating-linear-gradient(-55deg, ${eventColor.opacity}, ${eventColor.opacity} 10px, ${eventColor.original} 10px, ${eventColor.original} 20px)`
    },

    onEventRendered(args) {
      if (this.isTimelineView && args.data.IsHoliday) {
        // prevent holiday event to render in timeline view
        this.cancelEvent(args)
      } else if (!this.isAgendaView) {
        if (this.isEventOnHold(args.data.event_status_id)) {
          args.element.style.background = this.linearGradientBg(args.data)
        } else {
          args.element.style.backgroundColor = this.eventDisplayColors(
            args.data
          ).backgroundColor
        }
        args.element.style.color = this.eventDisplayColors(args.data).textColor
      } else {
        args.element.children[0].style.borderLeft = `3px solid ${
          this.eventDisplayColors(args.data).backgroundColor
        }`
        args.element.children[0].style.width = '100%'
      }

      // Remove resize handlers when event has an order_job_element_id which is not a placeholder event.
      if (args.data.order_job_element_id) {
        this.removeEventResizeHandlers(args)
      }

      // Change color of the date for holiday
      if (args.data.IsHoliday) {
        this.$nextTick(() => {
          this.addHolidayCustomClass(args)
        })
      }
    },

    handleEventDetailsUpdate(data, isAllDayEvent) {
      // disable setting default timezone to accommodate for system timezone changes
      momentTz.tz.setDefault()

      // Reset bulk selected if there's any
      this.setBulkActionSelectedEvents([])

      const selectedStartTime = momentTz(data.StartTime)
      const selectedEndTime = momentTz(data.EndTime)
      const durationDiff = momentTz
        .duration(selectedEndTime.diff(selectedStartTime))
        .asDays()

      const isSelectedEventAllDay = durationDiff > 1 ? true : isAllDayEvent

      const newStartTime = isSelectedEventAllDay
        ? selectedStartTime.startOf('day')
        : selectedStartTime

      const isEndTimeMidnight =
        selectedEndTime.format('HH:mm:ss') === '00:00:00'

      const newEndTime =
        isSelectedEventAllDay && !isEndTimeMidnight
          ? selectedEndTime.add(1, 'day').startOf('day')
          : selectedEndTime

      let payload = {
        from: newStartTime.format('YYYY-MM-DD HH:mm:ss'),
        to: newEndTime.format('YYYY-MM-DD HH:mm:ss'),
        name: data.name,
        event_type_id: data.event_type_id,
        is_all_day: isSelectedEventAllDay,
      }

      // On timeline view assign event to freelancer, unassigned or user
      if (this.isTimelineView) {
        payload = this.getUpdateEventAssignToPayload(payload, data)
      }

      if (this.$route.name === 'project.details.schedule') {
        this.updateProjectEventDetails({
          eventId: data.Id,
          includes: ApiCustomIncludes.projectEventsList,
          payload: payload,
        })
      } else {
        this.updateEventDetails({
          eventId: data.Id,
          includes: this.eventsListPayload.include,
          payload: payload,
        })
      }

      this.setEventActionHistory({
        type: 'edit',
        originalPayload: {
          id: data.id,
          from: data.from,
          to: data.to,
          user_id: data.user_id,
          freelancer: data.meta_data ? data.meta_data.freelancer : null,
          name: data.name,
          is_all_day: data.is_all_day,
        },
      })
    },

    getUpdateEventAssignToPayload(payload, eventData) {
      if (eventData.UserGroupId === EventUserGroups.UNASSIGNED) {
        payload.user_id = null
        payload.freelancer = null
      } else if (eventData.TeamGroupId === EventUserGroups.FREELANCER) {
        payload.freelancer = eventData.UserGroupId
        payload.user_id = null
      } else {
        payload.user_id = eventData.UserGroupId
        payload.freelancer = null
      }

      return payload
    },

    handleBulkUpdateEvents(args) {
      const bulkEventPayloadArray = []
      const selectedEvents = uniqWith(
        args.selectedData,
        (firstEvent, secondEvent) => {
          return firstEvent.id === secondEvent.id
        }
      )

      const originalPayloadArray = []

      forEach(selectedEvents, (selectedEvent) => {
        if (!selectedEvent.IsReadonly) {
          const startTime = this.$options.filters.momentFormat(
            selectedEvent.StartTime,
            'YYYY-MM-DD HH:mm:ss'
          )
          const endDateTime = momentTz(startTime)
            .add(selectedEvent.duration, 'seconds')
            .format('YYYY-MM-DD HH:mm:ss')

          let payload = {
            id: selectedEvent.id,
            from: startTime,
            to: endDateTime,
          }

          // On timeline view assign event to freelancer, unassigned or user
          if (this.isTimelineView) {
            payload = this.getUpdateEventAssignToPayload(payload, selectedEvent)
          }

          bulkEventPayloadArray.push(payload)

          originalPayloadArray.push({
            id: selectedEvent.id,
            from: selectedEvent.from,
            to: selectedEvent.to,
            user_id: selectedEvent.user_id,
            freelancer: selectedEvent.meta_data
              ? selectedEvent.meta_data.freelancer
              : null,
            name: selectedEvent.name,
            is_all_day: selectedEvent.is_all_day,
          })
        }
      })

      if (!isEmpty(bulkEventPayloadArray)) {
        this.bulkUpdateEvents({
          payload: {
            events: bulkEventPayloadArray,
          },
        })

        this.setEventActionHistory({
          type: 'bulkEdit',
          originalPayload: originalPayloadArray,
        })
      }
    },

    handleBulkEventDrag(args) {
      const selectedSetUserGroupIds = uniq(
        map(this.selectedEventsArray, (event) => event.UserGroupId)
      )
      // When projectDetails property is defined
      // Prevent updating other projects
      if (this.projectDetails?.id) {
        const hasEventFromOtherProjects = some(
          this.selectedEventsArray,
          (event) => this.isEventFromOtherProject(event)
        )

        if (hasEventFromOtherProjects) {
          this.cancelEvent(args)

          this.showBulkUpdateAlert({
            title: 'Bulk Update',
            message: 'Please select events from this project only',
          })

          return
        }
      }

      // This cancel event when all selected events are external events
      if (
        every(this.selectedEventsArray, (event) =>
          this.isExternalEvent(event.event_type_id)
        )
      ) {
        this.cancelEvent(args)
        return
      }

      if (this.isTimelineView && size(selectedSetUserGroupIds) > 1) {
        this.cancelEvent(args)

        this.showBulkUpdateAlert({
          title: 'Bulk Update',
          message: 'Please select events with the same assignee only',
        })
      } else {
        // validate all selected data if it has new date or assignee
        const hasNewDateOrAssignee = some(
          args.selectedData,
          (selectedEvent) => {
            return this.isDateOrUserGroupChanged(selectedEvent)
          }
        )

        if (hasNewDateOrAssignee) {
          this.handleBulkUpdateEvents(args)
        } else {
          this.updateEventSettingsDataSource()
        }
      }
    },

    handleDragStop(args) {
      // TODO: Optimize this multiple if statement

      if (this.isMobileScreen) {
        // We have to cancel the event at this point, otherwise it won't get cancelled if we wait for sweet alert
        // If it's confirmed, the return from API will update the calendar events
        this.cancelEvent(args)
        return
      }

      // When in timeline view, update event details only when UserGroupId is available.
      // This prevents event to be drag on group
      if (
        this.isTimelineView &&
        (!args.data.UserGroupId ||
          some(
            args.selectedData,
            (selectedEvent) => !selectedEvent.UserGroupId
          ))
      ) {
        this.cancelEvent(args)
        return
      }

      // For bulk update
      if (size(args.selectedData) > 1) {
        this.handleBulkEventDrag(args)
      } else {
        // When projectDetails property is defined
        // Prevent updating other projects
        if (this.projectDetails?.id) {
          if (this.isEventFromOtherProject(args.data)) {
            this.cancelEvent(args)

            this.showBulkUpdateAlert({
              title: 'Event cannot be updated',
              message: 'Please select event from this project only',
            })

            return
          }
        }

        // For single event update
        if (this.isExternalEvent(args.data.event_type_id)) {
          this.cancelEvent(args)
        } else {
          if (this.isDateOrUserGroupChanged(args.data)) {
            this.handleEventDetailsUpdate(args.data, false)
          }
        }
      }
    },

    handleBulkSelect() {
      const selectedEventIds = map(
        this.$refs.vmsScheduler.ej2Instances.selectedElements,
        (element) => {
          return parseInt(element.dataset.id.split('_').pop())
        }
      )

      let tempSelectedEvents = []

      if (size(selectedEventIds) > 0) {
        tempSelectedEvents = filter(
          this.eventSettings.dataSource,
          (eventList) => {
            return includes(selectedEventIds, eventList.id)
          }
        )
      } else {
        tempSelectedEvents = []
      }

      this.selectedEventsArray = tempSelectedEvents

      this.$forceUpdate()
    },

    clearSelectedCells() {
      const selectedCells = this.$refs.vmsScheduler.$el.querySelectorAll(
        '.e-selected-cell'
      )
      forEach(selectedCells, (cell) => cell.classList.remove('e-selected-cell'))
    },

    cancelEvent(args) {
      args.cancel = true
    },

    handleEventDoubleClick(args) {
      this.cancelEvent(args)

      // This will open edit modal when
      // no projectDetails id is available from where this mixin is used
      // or when projectDetails id is available but the the event is from other project
      if (
        !(this.projectDetails?.id && this.isEventFromOtherProject(args.event))
      ) {
        this.openEditModal(args.event)
      }
    },

    handleResizeStop(args) {
      // TODO: Optimize this multiple if statement

      if (this.isMobileScreen) {
        // We have to cancel the event at this point, otherwise it won't get cancelled if we wait for sweet alert
        // If it's confirmed, the return from API will update the calendar events
        this.cancelEvent(args)
        return
      }

      if (
        !this.isExternalEvent(args.data.event_type_id) &&
        this.isDateOrUserGroupChanged(args.data)
      ) {
        this.handleEventDetailsUpdate(args.data, !!args.data.is_all_day)
      } else {
        this.cancelEvent(args)
        return
      }
    },

    getPopoverTooltipId(eventId, type) {
      return `appointment-${eventId}-${type}`
    },

    getPopoverBodyStyle(data) {
      if (this.isEventOnHold(data.event_status_id)) {
        return `background: ${this.linearGradientBg(data)}; color: ${
          this.eventDisplayColors(data).textColor
        }`
      }

      return `background-color: ${
        this.eventDisplayColors(data).backgroundColor
      }; color: ${this.eventDisplayColors(data).textColor}`
    },

    isGoogleCalendarEventType(eventTypeId) {
      return (
        eventTypeId ===
        VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT
      )
    },

    isBambooHrEventType(eventTypeId) {
      return eventTypeId === VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT
    },

    isExternalEvent(eventTypeId) {
      return (
        this.isGoogleCalendarEventType(eventTypeId) ||
        this.isBambooHrEventType(eventTypeId)
      )
    },

    isPlaceholderEventType(event) {
      return (
        !event.order_job_element_id &&
        !this.isExternalEvent(event.event_type_id) &&
        !this.isPublicHolidayEvent(event.event_type_id)
      )
    },

    eventName(event) {
      return this.isBambooHrEventType(event.event_type_id)
        ? 'Bamboo Leave'
        : event.name
    },

    openEditModal(event) {
      // Reset timezone to default before opening event modal
      momentTz.tz.setDefault(this.defaultCalendarTimezone)

      // Fetch event details when requesting event is not the same as selectedEvent
      // to prevent requesting event details that is already available.
      if (this.selectedEvent?.id !== event.id) {
        this.getEventDetails({
          eventId: event.id,
          include: ApiCustomIncludes.eventsDetails,
        })

        this.setSelectedEvent(event)
      }

      this.setShowEventsModal(true)
    },

    handleDeleteEvent(event) {
      SweetAlert.fire({
        title: 'Delete Event',
        html: `Are you sure you want to delete ${event.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteEvent({ eventId: event.id })

          this.setEventActionHistory({
            type: 'delete',
            originalPayload: {
              id: event.id,
              from: event.from,
            },
          })
        }
      })
    },

    handleEventRightClicked(args) {
      let targetElement = args.target
      let selectedTarget = closest(
        targetElement,
        `.e-appointment,.e-work-cells,.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells`
      )

      if (selectedTarget?.classList.contains('e-appointment')) {
        let eventObj = this.$refs.vmsScheduler.ej2Instances.getEventDetails(
          selectedTarget
        )

        // prevent opening context menu for readonly events (holidays and external events)
        if (eventObj.IsReadonly) {
          return
        }

        // prevent opening context menu for project specific calendar when event is external event
        if (
          this.projectDetails?.id &&
          (this.isEventFromOtherProject(eventObj) ||
            some(this.selectedEventsArray, (event) =>
              this.isEventFromOtherProject(event)
            ))
        ) {
          this.showBulkUpdateAlert({
            title: 'Event cannot be updated',
            message: 'Please select event from this project only',
          })
          this.clearSelectedEvents()
          this.setSelectedEvent(null)
          return
        }

        if (
          size(this.selectedEventsArray) > 1 &&
          find(this.selectedEventsArray, { Id: eventObj.Id })
        ) {
          this.$refs.calendarBulkActionContextMenu.$refs.menuObj.open(
            args.clientY,
            args.clientX
          )
        } else {
          this.clearSelectedEvents()
          this.setSelectedEvent(eventObj)

          this.$refs.calendarActionContextMenu.$refs.menuObj.open(
            args.clientY,
            args.clientX
          )
        }
      } else {
        this.clearSelectedEvents()
        this.setSelectedEvent(null)
      }
    },

    handleActionContextMenuSelect(menuItem) {
      if (menuItem && !isEmpty(this.selectedEvent)) {
        switch (menuItem.id) {
          case 'delete':
            this.handleDeleteEvent(this.selectedEvent)
            break
          case 'duplicate':
            this.handleEventDuplicate([this.selectedEvent])
            break
          default:
            break
        }
      }
    },

    handleBulkActionContextMenuSelect(menuItem) {
      if (menuItem) {
        this.setBulkActionSelectedEvents(this.selectedEventsArray)

        switch (menuItem.id) {
          case 'bulk-edit':
            this.setShowEventsBulkEditModal(true)
            break
          case 'bulk-duplicate':
            this.handleEventDuplicate(this.bulkActionSelectedEvents)
            break
          case 'bulk-delete':
            this.handleBulkEventDelete()
            break
          default:
            break
        }
      }
    },

    handleBulkEventDelete() {
      const alertContent = `
        <div class="vd-margin-top-small">Are you sure you want to bulk delete <strong>${size(
          this.selectedEventsArray
        )}</strong> selected events?</div>
      `

      SweetAlert.fire({
        title: 'Bulk Delete Events',
        html: alertContent,
        showCancelButton: true,
        confirmButtonText: 'Bulk Delete',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.bulkDeleteEvents({
            payload: {
              event_ids: map(this.bulkActionSelectedEvents, 'id'),
            },
          })

          this.setEventActionHistory({
            type: 'bulkDelete',
            originalPayload: {
              event_ids: map(this.bulkActionSelectedEvents, 'id'),
            },
          })
        }

        this.selectedEventsArray = []
      })
    },

    handleEventDuplicate(eventsArray) {
      let alertContent, alertTitle, alertConfirmButtonText

      if (size(eventsArray) > 1) {
        alertTitle = 'Bulk Duplicate Events'
        alertContent = `
          <div class="vd-margin-top-small">Are you sure you want to bulk duplicate <strong>${size(
            eventsArray
          )}</strong> selected events?</div>
        `
        alertConfirmButtonText = 'Bulk Duplicate'
      } else {
        alertTitle = 'Duplicate Event'
        alertContent = `
          <div class="vd-margin-top-small">Are you sure you want to duplicate this event?</div>
        `
        alertConfirmButtonText = 'Duplicate Event'
      }

      SweetAlert.fire({
        title: alertTitle,
        html: alertContent,
        showCancelButton: true,
        confirmButtonText: alertConfirmButtonText,
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const filteredSelectedEvents = filter(eventsArray, (event) => {
            return !this.isExternalEvent(event.event_type_id)
          })

          this.bulkDuplicateEvents({
            payload: {
              event_ids: map(filteredSelectedEvents, 'id'),
            },
          })
        }

        this.clearSelectedEvents()
        this.setSelectedEvent(null)
      })
    },

    clearSelectedEvents() {
      this.selectedEventsArray = []
      forEach(
        this.$refs.vmsScheduler.ej2Instances.selectedElements,
        (element) => removeClass([element], 'e-appointment-border')
      )
      this.$refs.vmsScheduler.ej2Instances.selectedElements = []
    },

    handlePopupOpen(args) {
      args.cancel = args.type === 'DeleteAlert' || args.type === 'EditEventInfo'
    },

    getFreelancerGroup(freelancer) {
      return {
        userId: freelancer,
        userName: freelancer,
        data: {
          full_name: freelancer,
          calendar_color: Colours.eventColours.freelancer,
        },
        teamId: EventUserGroups.FREELANCER,
        teamName: 'Freelancers',
      }
    },

    getEventAssignTo(eventData) {
      const eventAssignTo = {}

      if (eventData.UserGroupId === EventUserGroups.UNASSIGNED) {
        eventAssignTo.assignTo = null
      } else if (eventData.TeamGroupId === EventUserGroups.FREELANCER) {
        eventAssignTo.freelancer = eventData.UserGroupId
      } else {
        eventAssignTo.assignTo = find(this.internalUsersList, {
          id: eventData.UserGroupId,
        })
      }
      return eventAssignTo
    },

    isUserGroupChanged(eventData) {
      let isUserGroupUpdated = eventData.user_id !== eventData.UserGroupId

      if (eventData.UserGroupId === EventUserGroups.UNASSIGNED) {
        isUserGroupUpdated =
          eventData.user_id !== null || eventData.meta_data?.freelancer
      } else if (eventData.TeamGroupId === EventUserGroups.FREELANCER) {
        isUserGroupUpdated =
          eventData.meta_data?.freelancer !== eventData.UserGroupId
      }

      return isUserGroupUpdated
    },

    isDateOrUserGroupChanged(event) {
      momentTz.tz.setDefault(this.defaultCalendarTimezone)
      const originalStartTime = this.$options.filters.unixToFormat(
        event.from,
        'YYYY-MM-DD HH:mm:ss'
      )

      momentTz.tz.setDefault()
      const newStartTime = this.$options.filters.momentFormat(
        event.StartTime,
        'YYYY-MM-DD HH:mm:ss'
      )

      momentTz.tz.setDefault(this.defaultCalendarTimezone)
      const originalEndTime = this.$options.filters.unixToFormat(
        event.to,
        'YYYY-MM-DD HH:mm:ss'
      )

      momentTz.tz.setDefault()
      const newEndTime = this.$options.filters.momentFormat(
        event.EndTime,
        'YYYY-MM-DD HH:mm:ss'
      )

      if (this.selectedViewType === 'Month') {
        const endDateDiff = momentTz
          .duration(
            momentTz(newEndTime).diff(
              momentTz(originalEndTime, 'YYYY-MM-DD HH:mm:ss')
            )
          )
          .asDays()

        const startDateDiff = momentTz
          .duration(
            momentTz(newStartTime).diff(
              momentTz(originalStartTime, 'YYYY-MM-DD HH:mm:ss')
            )
          )
          .asDays()

        return endDateDiff >= 1 || endDateDiff < 0 || startDateDiff !== 0
      } else {
        if (
          newStartTime !== originalStartTime ||
          newEndTime !== originalEndTime
        ) {
          return true
        }

        if (this.isTimelineView) {
          return this.isUserGroupChanged(event)
        }
      }

      return false
    },

    showBulkUpdateAlert({ title, message }) {
      SweetAlert.fire({
        title: title,
        html: `<div class="vd-margin-top-small">${message}</div>`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Close',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.clearSelectedEvents()
          this.setSelectedEvent(null)
        }
      })
    },

    handleSelect(args) {
      if (args.requestType === 'eventSelect') {
        if (args.data.IsReadonly) {
          this.deSelectReadOnlyEvent(args)
        }

        this.handleBulkSelect()
      }

      if (args.data && args.requestType === 'cellSelect') {
        this.cancelEvent(args)
        momentTz.tz.setDefault()

        const start = momentTz(args.data.StartTime)
        const end = momentTz(args.data.EndTime)

        const isEndDateMidnight =
          momentTz(start)
            .clone()
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss') ===
          momentTz(end).format('YYYY-MM-DD HH:mm:ss')

        const durationDiff = momentTz.duration(end.diff(start)).asDays()

        // Set isAllDayEvent true if is all day selected and the duration different is more than 1 day
        const isAllDayEvent = args.data.IsAllDay && durationDiff > 1

        const isWeekViewWithMultipleDays =
          this.selectedViewType === 'Week' &&
          !isEndDateMidnight &&
          momentTz(start).format('YYYY-MM-DD') !==
            momentTz(end).format('YYYY-MM-DD')

        this.setEventsModalPayload(
          args,
          start,
          end,
          isAllDayEvent,
          isWeekViewWithMultipleDays
        )

        this.showEventsModal(isAllDayEvent, isWeekViewWithMultipleDays)

        // manually highlight selected cell
        args.element.classList.add('e-selected-cell')

        if (!isAllDayEvent && !isWeekViewWithMultipleDays) {
          this.showContextMenu(args)
        }
      }
    },

    isEventFromOtherProject(event) {
      // check if isCurrentProjectEvent is a function from where this mixin is used
      // and run it to check if event is not from the current project
      return (
        isFunction(this.isCurrentProjectEvent) &&
        !this.isCurrentProjectEvent(event)
      )
    },

    setEventsModalPayload(
      args,
      start,
      end,
      isAllDayEvent,
      isWeekViewWithMultipleDays
    ) {
      let payload = {
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        startTime: args.data.IsAllDay ? '' : start.format('HH:mm:ss'),
        endTime: args.data.IsAllDay ? '' : end.format('HH:mm:ss'),
        isAllDayEvent: isAllDayEvent || isWeekViewWithMultipleDays,
      }

      const isEndDateNextDay = momentTz(end).format('HH:mm:ss') === '00:00:00'

      if ((isAllDayEvent || isWeekViewWithMultipleDays) && isEndDateNextDay) {
        payload.endDate = end
          .clone()
          .subtract(1, 'm')
          .endOf('day')
          .format('YYYY-MM-DD')
      }

      if (this.isTimelineView) {
        payload = assign(payload, this.getEventAssignTo(args.data))
      }

      // If projectDetails.id is defined, add it to schedule object
      if (this.projectDetails?.id) {
        payload = assign(payload, { projectId: this.projectDetails.id })
        this.setProjectsList([
          {
            id: this.projectDetails.id,
            name: this.projectDetails.name,
          },
        ])
      }

      this.setSelectedEvent(null)

      this.setSelectedSchedules(payload)
    },

    showEventsModal(isAllDayEvent, isWeekViewWithMultipleDays) {
      if (isAllDayEvent || isWeekViewWithMultipleDays) {
        this.setShowEventsPlaceholdersCreateModal(true)
        this.setShowEventsModal(true)

        // clear highlighted cells
        this.clearSelectedCells()
        return
      }
    },

    showContextMenu(args) {
      // open context menu
      let top = args.event.clientY
      let left = args.event.clientX

      // Open the context menu component
      this.$refs.calendarContextMenu.$refs.menuObj.open(top, left)
    },

    onContextMenuBeforeOpen() {
      // hide duplicate event option when event is external event.
      if (this.isExternalEvent(this.selectedEvent.event_type_id)) {
        this.$refs.calendarActionContextMenu.$refs.menuObj.hideItems(
          ['duplicate'],
          true
        )
      } else {
        this.$refs.calendarActionContextMenu.$refs.menuObj.showItems(
          ['duplicate'],
          true
        )
      }
    },

    onBulkContextMenuBeforeOpen() {
      const canBulkDuplicateEvents = every(
        this.selectedEventsArray,
        (event) => {
          return this.isExternalEvent(event.event_type_id)
        }
      )

      // hide bulk-duplicate when all selected events are external events
      if (canBulkDuplicateEvents) {
        this.$refs.calendarBulkActionContextMenu.$refs.menuObj.hideItems(
          ['bulk-duplicate'],
          true
        )
      } else {
        this.$refs.calendarBulkActionContextMenu.$refs.menuObj.showItems(
          ['bulk-duplicate'],
          true
        )
      }
    },

    isPublicHolidayEvent(eventTypeId) {
      return (
        eventTypeId === VmsConstants.eventTypes.EVENT_TYPE_ID_PUBLIC_HOLIDAYS
      )
    },

    deSelectReadOnlyEvent(args) {
      args.cancel = true
      args.element.classList.remove('e-appointment-border')
      args.element.blur()
      this.$refs.vmsScheduler.ej2Instances.selectedElements.pop()
    },

    isEventReadOnly(eventData) {
      return (
        this.isPublicHolidayEvent(eventData.event_type_id) ||
        this.isExternalEvent(eventData.event_type_id) ||
        !!(this.projectDetails?.id && this.isEventFromOtherProject(eventData))
      )
    },

    removeEventResizeHandlers(args) {
      const resizeLeft = args.element.querySelector('.e-left-handler')
      const resizeRight = args.element.querySelector('.e-right-handler')
      resizeLeft?.remove()
      resizeRight?.remove()
    },

    addHolidayCustomClass(args) {
      const dataDate = momentTz(args.data.StartTime).startOf('day').valueOf()
      const dateCell = document.querySelector(
        `.e-schedule-table>tbody>tr>td[data-date='${dataDate}']`
      )
      if (dateCell) {
        dateCell.classList.add('e-date--holiday')
      }
    },
  },
}

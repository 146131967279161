<template>
  <div class="vd-margin-top-medium">
    <div class="vd-margin-bottom-medium">
      <div class="vd-margin-bottom-medium">
        <strong>
          <span v-if="eventDetails.state" class="vd-margin-right-extra-small"
            >{{ eventDetails.state.name }} -
          </span>
          <span v-if="eventDetails.event_type">
            {{ eventDetails.event_type.name }}</span
          >
          <span v-if="isPlaceholderEventType"> (Placeholder)</span>
        </strong>
      </div>
      <div v-if="!isExternalEvent">
        <div class="vd-display-flex">
          <span class="vd-margin-right-extra-small"
            ><strong>Event name:</strong></span
          >
          <span>{{ eventDetails.name | truncateString(40) }}</span>
          <clipboard-copy
            v-if="!eventDetails.IsReadonly"
            :copy-content="eventDetails.name"
          >
            <i
              class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
            ></i>
          </clipboard-copy>
        </div>
        <div v-if="eventDetails.description">
          <span class="vd-margin-right-extra-small">
            <strong>Description:</strong>
          </span>
          <span>{{ eventDetails.description }}</span>
        </div>
      </div>

      <div v-if="canDisplayAddress" class="vd-margin-bottom-small">
        <div class="vd-display-flex">
          <span class="vd-margin-right-extra-small"
            ><strong>Address:</strong></span
          >
          <span v-if="eventDetails.address">{{ eventDetails.address }}</span>
          <span v-if="hasFullAddress">{{
            eventDetails.order_job_element.order_job.full_address
          }}</span>
          <clipboard-copy
            v-if="canDisplayAddress"
            :copy-content="eventDisplayAddress"
          >
            <i
              class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
            ></i>
          </clipboard-copy>
        </div>
      </div>

      <div v-if="isEventDetailsLoading" class="vd-light-blue">Loading...</div>
      <div v-else>
        <div v-if="eventDetails.office">
          <div class="vd-display-flex">
            <span class="vd-margin-right-extra-small"
              ><strong>Office:</strong></span
            >
            <span>{{ eventDetails.office.name }}</span>
            <clipboard-copy :copy-content="eventDetails.office.name">
              <i
                class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
              ></i>
            </clipboard-copy>
          </div>
        </div>
        <div v-if="eventDetails.project">
          <div class="vd-display-flex">
            <span class="vd-margin-right-extra-small"
              ><strong>Project ID:</strong></span
            >
            <span>{{ eventDetails.project.id }}</span>
            <clipboard-copy :copy-content="`${eventDetails.project.id}`">
              <i
                class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
              ></i>
            </clipboard-copy>
          </div>
          <div class="vd-display-flex">
            <span class="vd-margin-right-extra-small"
              ><strong>Project name:</strong></span
            >
            <span>{{ eventDetails.project.name | truncateString(38) }}</span>
            <clipboard-copy :copy-content="eventDetails.project.name">
              <i
                class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
              ></i>
            </clipboard-copy>
          </div>
        </div>
        <div
          v-if="eventDetails.meta_data && eventDetails.meta_data.project_name"
        >
          <span class="vd-margin-right-extra-small"
            ><strong>Project name:</strong></span
          >
          <span>{{ eventDetails.meta_data.project_name }}</span>
        </div>
        <div v-if="eventDetails.project && eventDetails.project.producer">
          <div class="vd-display-flex">
            <span class="vd-margin-right-extra-small"
              ><strong>Project manager:</strong></span
            >
            <span>{{ eventDetails.project.producer.full_name }}</span>
            <clipboard-copy
              :copy-content="eventDetails.project.producer.full_name"
            >
              <i
                class="vd-icon icon-copy vd-margin-left-small vd-cursor-pointer vd-hover-light-blue"
              ></i>
            </clipboard-copy>
          </div>
        </div>
      </div>
    </div>
    <div class="vd-display-flex vd-align-end vd-flex-wrap vd-text-small">
      <div v-if="eventDetails.is_all_day">
        <span>{{ eventDetails.from | unixToFormat('dddd, D MMMM') }}</span>
        <template v-if="endDate">
          <span class="vd-margin-inline-extra-small">-</span>
          <div>
            {{ endDate }}
          </div>
        </template>
      </div>
      <div v-else>
        <div>{{ eventDetails.from | unixToFormat('dddd, D MMMM') }}</div>
        <span>{{ eventDetails.from | unixToFormat('hh:mm A') }}</span>
        <span class="vd-margin-inline-extra-small">-</span>
        <span>{{ eventDetails.to | unixToFormat('hh:mm A') }}</span>
      </div>
      <div v-if="assignTo" class="vd-text-align-right vd-margin-left-auto">
        <div class="vd-display-flex vd-align-center">
          <span class="vd-margin-right-extra-small">{{
            assignTo.full_name
          }}</span>
          <user-profile
            :user="assignTo"
            :custom-avatar-font-size="20"
          ></user-profile>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'
import AppConfig from '@configs/app-config'
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'
import Colours from '@configs/colours'
import EventTypes from '@configs/event-types'
import EventTypesMixin from '@mixins/event-types-mixin'
import ClipboardCopy from '@components/events/events-popover/events-popover-clipboard-copy'

export default {
  components: {
    UserProfile,
    ClipboardCopy,
  },
  mixins: [FiltersMixin, EventTypesMixin],
  props: {
    eventData: {
      type: Object,
      required: true,
    },

    // Getters and Actions passed here
    // because for some reason $store is undefined inside syncfusion template
    // where this component is used.
    selectedEvent: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isEventDetailsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    getEventDetails: {
      type: Function,
      required: true,
    },

    isPlaceholderEventType: {
      type: Boolean,
      required: false,
      default: false,
    },

    isExternalEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasFullAddress() {
      return (
        this.eventDetails.order_job_element &&
        this.eventDetails.order_job_element.order_job &&
        this.eventDetails.order_job_element?.order_job?.full_address
      )
    },

    canDisplayAddress() {
      const hasAddress = this.eventDetails.address || this.hasFullAddress
      // return true if placeholder event
      // Only Placeholder event does not event type id.
      if (!this.eventData.order_job_element_id) {
        return hasAddress
      } else {
        const eventTypeId = this.eventData?.event_type?.id
        const eventJobType = this.getJobTypeByEventTypeId(eventTypeId)

        // return true if event is filming details event and have address
        return (
          eventJobType === EventTypes.jobTypes.FILMING_DETAILS && hasAddress
        )
      }
    },

    endDate() {
      const from = momentTz.unix(this.eventDetails.from)
      const to = momentTz.unix(this.eventDetails.to)
      // Check if difference is > 1 then return end date - 1 day
      // because end date is 12:00am of the next day of the actual end date we want to show
      return to.diff(from, 'day') > 1
        ? to.subtract(1, 'day').format('dddd, D MMMM')
        : '' // return nothing because it is the same as our start date
    },

    assignTo() {
      if (this.eventDetails.user) {
        return this.eventDetails.user
      }

      if (this.eventDetails.meta_data?.freelancer) {
        return {
          full_name: this.eventDetails.meta_data.freelancer,
          calendar_color: Colours.eventColours.freelancer,
        }
      }

      return null
    },

    eventDetails() {
      // return both selectedEvent and eventData when they are the same event
      if (this.selectedEvent?.id === this.eventData.id) {
        return {
          ...this.eventData,
          ...this.selectedEvent,
        }
      }

      return this.eventData
    },

    eventDisplayAddress() {
      let address = ''

      if (this.eventDetails.address) {
        address = this.eventDetails.address
      }

      if (this.hasFullAddress) {
        address = this.eventDetails.order_job_element.order_job.full_address
      }

      return address
    },
  },

  // Setting the timezone back to default when this component is created
  created() {
    momentTz.tz.setDefault(AppConfig.defaultTimezone)
  },

  beforeDestroy() {
    momentTz.tz.setDefault()
  },

  mounted() {
    if (!this.eventData.IsReadonly) {
      this.getEventDetails({
        eventId: this.eventData.id,
        include: ApiCustomIncludes.eventsDetails,
      })
    }
  },
}
</script>
